<script>
import vSelect from 'vue-select'
import { GetProductFieldsByProductId } from '@/libs/Api/productField'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    vSelect
  },
  props: {
    label: {
      type: String,
    },
    multiple: {
      type: Boolean,
      default: false
    },
    pick: {
      type: String,
    }
  },
  data() {
    return {
      selectedItems: null,
      options: []
    }
  },
  methods: {
    async getProductFields() {
      let _this = this
      let getProductFieldsByProductId = new GetProductFieldsByProductId(_this)
      let data = {
        productId: _this.$route.query.productId,
        pageNumber: 1,
        count: 1000
      }
      getProductFieldsByProductId.setParams(data)
      await getProductFieldsByProductId.fetch(function (content) {
        _this.options = content.data.data
        _this.options.forEach((item) => {
          item['name'] = item.productFieldDetails[0].name
        })
      }, function (error) {
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            text: error.message,
            title: `error`,
            variant: 'danger',
          },
        })
      })
    }
  },
  watch:{
    selectedItems:function(val){
      this.$emit('getSelectedProductFieldId',val)
    }
  },
  mounted() {
    this.getProductFields()
  }
}
</script>

<template>
  <v-select
      v-model="selectedItems"
      :options="options"
      :reduce="name => name[pick]"
      dir="ltr"
      placeholder="Choose Product Field"
      :label="label"
      :multiple="multiple"
  />
</template>

<style scoped>

</style>