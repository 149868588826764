<script>
import vSelect from 'vue-select'
import { GetProductFieldFeaturesByFieldId } from '@/libs/Api/productField'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    vSelect
  },
  props: {
    label: {
      type: String,
    },
    multiple: {
      type: Boolean,
      default: false
    },
    pick: {
      type: String,
    },
    productFieldId: {
      type: Number
    }
  },
  data() {
    return {
      selectedItems: null,
      options: []
    }
  },
  methods: {
    async getProductFieldFeatures() {
      let _this = this
      let getProductFieldFeaturesByFieldId = new GetProductFieldFeaturesByFieldId(_this)
      let data = {
        productFieldDetailId: _this.productFieldId,
        pageNumber: 1,
        count: 1000
      }
      getProductFieldFeaturesByFieldId.setParams(data)
      await getProductFieldFeaturesByFieldId.fetch(function (content) {
        _this.options = content.data.data
      }, function (error) {
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            text: error.message,
            title: `error`,
            variant: 'danger',
          },
        })
        _this.showOverlay = false
      })
    }
  },
  watch: {
    selectedItems: function (val) {
      this.$emit('getSelectedFeatures', val)
    }
  },
  mounted() {
    this.getProductFieldFeatures()
  }
}
</script>

<template>
  <v-select
      v-model="selectedItems"
      :options="options"
      :reduce="name => pick? name[pick] : name"
      dir="ltr"
      :label="label"
      placeholder="Choose Features"
      :multiple="multiple"
  />
</template>

<style scoped>

</style>