<template>
  <b-overlay :show="showOverlay ">
    <b-col class="mb-1 bg-white p-0" cols="12">
      <DynamicBreadCrumb :items="pageBreadcrumb"></DynamicBreadCrumb>
    </b-col>
    <b-card v-if="!currentRangeSlider" class="data-edit-wrapper">
      <b-form>
        <b-row>
          <b-col class="mb-1" md="12">
            <h1>Create Product Slider Range</h1>
          </b-col>
          <b-col class="mb-1" md="12">
            <b-alert show variant="danger" class="p-2">
              <strong>Attention! </strong>
              <span>Please Fill In All The Fields And Define All The Ranges You Have  In Mind. Changes Cannot Be Modified After Creation And Can Only Be Deleted.</span>
            </b-alert>
          </b-col>
          <b-col cols="6">
            <b-form-group
                label="First Title"
            >
              <validation-provider
                  #default="{ errors }"
                  rules="required"
              >
                <b-form-input
                    id="data-edit-seoTitle"
                    v-model="rangeSliderDTO.currentTitle"
                    :state="errors.length > 0 ? false:null"
                />
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group
                label="Second Title"
            >
              <validation-provider
                  #default="{ errors }"
                  rules="required"
              >
                <b-form-input
                    id="data-edit-seoTitle"
                    v-model="rangeSliderDTO.desireTitle"
                    :state="errors.length > 0 ? false:null"
                />
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col class="mb-2 mt-1" cols="12">
            <strong>Create New Range</strong>
          </b-col>
          <b-col md="4" cols="12">
            <b-form-group
                label="Min Condition"
            >
              <validation-provider
                  #default="{ errors }"
                  rules="required"
              >
                <b-form-input
                    v-model="rangeDTO.minCondition"
                    id="data-edit-seoTitle"
                    type="number"
                    :state="errors.length > 0 ? false:null"
                />
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4" cols="12">
            <b-form-group
                label="Max Condition"
            >
              <validation-provider
                  #default="{ errors }"
                  rules="required"
              >
                <b-form-input
                    v-model="rangeDTO.maxCondition"
                    type="number"
                    id="data-edit-seoTitle"
                    :state="errors.length > 0 ? false:null"
                />
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4" cols="12">
            <b-form-group
                label="Ratio"
            >
              <validation-provider
                  #default="{ errors }"
                  rules="required"
              >
                <b-form-input
                    v-model="rangeDTO.ratio"
                    id="data-edit-seoTitle"
                    type="number"
                    :state="errors.length > 0 ? false:null"
                />
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col class="" md="4">
            <b-button
                @click="createNewRange"
                variant="warning"
            >
              Add
            </b-button>
          </b-col>
          <b-col class="mt-2" cols="12">
            <b-table
                ref="refUserListTable"
                :fields="rangeDetailsColumns"
                :items="rangeSliderDTO.productSliderRangeDetails"
                bordered
                class="position-relative"
                empty-text="Nothing found !"
                primary-key="id"
                responsive
                show-empty
                striped
            >
              <template #cell(operation)="data">

                <div @click="rangeSliderDTO.productSliderRangeDetails.splice(data.index,1)" class="cursor-pointer">
                  <feather-icon
                      class="text-danger"
                      icon="TrashIcon"
                      size="20"
                  />
                </div>
              </template>
            </b-table>
          </b-col>
          <b-col class="mb-2 mt-1" cols="12">
            <strong>Select Product Feature Ids</strong>
          </b-col>
          <b-col class="mt-2" md="4" cols="12">
            <ProductFieldsPicker pick="productFieldId" label="name"
                                 @getSelectedProductFieldId="setSelectedProductFieldId"
            ></ProductFieldsPicker>
          </b-col>
          <b-col v-if="selectedProductFieldId" class="mt-2" md="4" cols="12">
            <FieldFeaturesPicker :key="selectedProductFieldId" @getSelectedFeatures="setSelectedFeatures"
                                 :product-field-id="selectedProductFieldId"
                                 label="name" multiple
            ></FieldFeaturesPicker>
          </b-col>
          <b-col class="mt-2" cols="12">
            <b-table
                ref="refUserListTable"
                :fields="featuresColumns"
                :items="rangeSliderDTO.productFeatureIds"
                bordered
                class="position-relative"
                empty-text="Nothing found !"
                primary-key="id"
                responsive
                show-empty
                striped
            >
              <template #cell(operation)="data">

                <div @click="rangeSliderDTO.productFeatureIds.splice(data.index,1)" class="cursor-pointer">
                  <feather-icon
                      class="text-danger"
                      icon="TrashIcon"
                      size="20"
                  />
                </div>
              </template>
            </b-table>
          </b-col>
          <b-col cols="12">
            <b-row>
              <b-col v-for="(item,idx) in currencies" md="3">
                <b-form-group
                    :label="`Price In ${item.name} *`"
                >
                  <b-form-input
                      id="data-edit-seoTitle"
                      :ref="`price${idx}`"
                      type="number"
                  />

                </b-form-group>
              </b-col>
            </b-row>

          </b-col>
          <b-col class="" md="4">
            <b-button
                @click="createNewRangeSlider"
                variant="success"
            >
              Create
            </b-button>
          </b-col>

        </b-row>
      </b-form>
    </b-card>
    <b-card v-else class="data-edit-wrapper">
      <b-form>
        <b-row>
          <b-col class="mb-1" md="12">
            <h1>Product Slider Range</h1>
          </b-col>
          <b-col cols="6">
            <b-form-group
                label="First Title"
            >
              <validation-provider
                  #default="{ errors }"
                  rules="required"
              >
                <b-form-input
                    id="data-edit-seoTitle"
                    disabled
                    v-model="currentRangeSlider.currentTitle"
                    :state="errors.length > 0 ? false:null"
                />
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group
                label="Second Title"
            >
              <validation-provider
                  #default="{ errors }"
                  rules="required"
              >
                <b-form-input
                    id="data-edit-seoTitle"
                    disabled
                    v-model="currentRangeSlider.desireTitle"
                    :state="errors.length > 0 ? false:null"
                />
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col class="mt-2" cols="12">
            <b-table
                ref="refUserListTable"
                :fields="rangeDetailsColumns"
                :items="currentRangeSlider.productSliderRangeDetails"
                bordered
                class="position-relative"
                empty-text="Nothing found !"
                primary-key="id"
                responsive
                show-empty
                striped
            >
              <template #cell(operation)="data">
                -

                <!--                <div @click="rangeSliderDTO.productSliderRangeDetails.splice(data.index,1)" class="cursor-pointer">-->
                <!--                  <feather-icon-->
                <!--                      class="text-danger"-->
                <!--                      icon="TrashIcon"-->
                <!--                      size="20"-->
                <!--                  />-->
                <!--                </div>-->
              </template>
            </b-table>
          </b-col>
          <b-col class="mb-2 mt-1" cols="12">
            <strong>Select Product Feature Ids</strong>
          </b-col>
          <b-col class="mt-2" cols="12">
            <b-table
                ref="refUserListTable"
                :fields="currentRangeFeaturesColumns"
                :items="currentRangeSlider.productSliderRangeDetailFeatures"
                bordered
                class="position-relative"
                empty-text="Nothing found !"
                primary-key="id"
                responsive
                show-empty
                striped
            >
              <template #cell(operation)="data">
                -
                <!--                <div @click="rangeSliderDTO.productFeatureIds.splice(data.index,1)" class="cursor-pointer">-->
                <!--                  <feather-icon-->
                <!--                      class="text-danger"-->
                <!--                      icon="TrashIcon"-->
                <!--                      size="20"-->
                <!--                  />-->
                <!--                </div>-->
              </template>
            </b-table>
          </b-col>
          <b-col cols="12">
            <b-row>
              <b-col v-for="(item,idx) in currentRangeSlider.productSliderRangeBasePrices" md="3">
                <b-form-group
                    :label="`Price In ${currencies.find(e=> e.id === item.currencyId).name} *`"
                >
                  <b-form-input
                      v-model="item.basePrice"
                      id="data-edit-seoTitle"
                      :ref="`price${idx}`"
                      type="number"
                      disabled
                  />

                </b-form-group>
              </b-col>
            </b-row>

          </b-col>
          <b-col class="" md="4">
            <b-button
                v-b-modal.modal-delete-range
                variant="danger"
            >
              Delete Range Slider
            </b-button>
          </b-col>

        </b-row>
      </b-form>
    </b-card>

    <b-modal
        id="modal-delete-range"
        cancelTitle="Cancel"
        centered
        ok-title="Delete"
        title="Delete Range Slider"
        @ok="deleteRangeSlider"
    >
      <div class="row">
        <div class="col-md-12">
          <span>Are you sure you want to delete this Range Slider ?</span>
        </div>
      </div>
    </b-modal>
  </b-overlay>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@validations'
import {
  BAlert,
  BBadge,
  BButton,
  BCard,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BLink,
  BModal,
  BOverlay,
  BRow,
  BTable,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import ckEditorMain from '@/views/components/ckEditorMain'
import Helper from '@/libs/Helper'
import MediaHandler from '@/views/components/utilities/MediaHandler.vue'
import Debouncer from '@/views/components/debouncer.vue'
import { CreateProductRangeSlider, DeleteProductSliderRange, GetProductSliderRangeById } from '@/libs/Api/ProductSlider'
import DynamicBreadCrumb from '@/views/components/utilities/DynamicBreadCrumb.vue'
import ProductFieldsPicker from '@/views/components/pickers/ProductFieldsPicker.vue'
import FieldFeaturesPicker from '@/views/components/pickers/FieldFeaturesPicker.vue'
import { GetAllCurrencies } from '@/libs/Api/Currencies'

export default {
  components: {
    FieldFeaturesPicker,
    ProductFieldsPicker,
    BLink,
    BTable,
    DynamicBreadCrumb,
    Debouncer,
    MediaHandler,
    BForm,
    ValidationProvider,
    ValidationObserver,
    ckEditorMain,
    BAlert,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    BButton,
    BBadge,
    BOverlay,
    BModal,
    vSelect,
  },
  name: 'manage-slider',
  title: 'manage slider',
  data() {
    return {
      required,
      showOverlay: false,
      baseUrl: Helper.baseUrl,
      currencies: [],
      currentRangeSlider: null,
      selectedProductFieldId: null,
      pageBreadcrumb: [
        {
          text: 'Manage Games',
          href: '/apps/games/manage-games',
          active: false
        },
        {
          text: `Manage Game`,
          href: `/apps/games/manage-game?gameId=${this.$route.query.gameId}`,
          active: false
        },
        {
          text: `Manage Game Service Category`,
          href: `/apps/games/game-service-categories/manage-game-service-category?gameId=${this.$route.query.gameId}&gameServiceCategoryId=${this.$route.query.gameServiceCategoryId}`,
          active: false
        },
        {
          text: `Manage Game Service`,
          href: `/apps/games/game-service-categories/game-service/manage-game-services?gameId=${this.$route.query.gameId}&gameServiceCategoryId=${this.$route.query.gameServiceCategoryId}&gameServiceId=${this.$route.query.gameServiceId}`,
          active: false
        },
        {
          text: `Manage Product`,
          href: `/apps/games/game-service-categories/game-service/products/manage-product?gameId=${this.$route.query.gameId}&gameServiceCategoryId=${this.$route.query.gameServiceCategoryId}&gameServiceId=${this.$route.query.gameServiceId}&productId=${this.$route.query.productId}`,
          active: false
        },
        {
          text: `Manage Product Slider`,
          href: `/apps/games/game-service-categories/game-service/products/product-slider/manage-slider?gameId=${this.$route.query.gameId}&gameServiceCategoryId=${this.$route.query.gameServiceCategoryId}&gameServiceId=${this.$route.query.gameServiceId}&productId=${this.$route.query.productId}`,
          active: true
        },
      ],
      rangeDTO: {
        minCondition: null,
        maxCondition: null,
        ratio: null,
      },
      myTableColumns: [
        {
          key: 'id',
          label: '#',
          sortable: true
        },
        {
          key: 'title',
          label: 'title',
          sortable: true
        },
        {
          key: 'subTitle',
          label: 'subTitle',
          sortable: true
        },
        {
          key: 'operation',
          label: 'operation'
        },
      ],
      rangeDetailsColumns: [
        {
          key: 'minCondition',
          label: 'Min Condition',
          sortable: true
        },
        {
          key: 'maxCondition',
          label: 'Max Condition',
          sortable: true
        },
        {
          key: 'ratio',
          label: 'Ratio'
        },
        {
          key: 'operation',
          label: 'Operation'
        },
      ],
      currentRangeFeaturesColumns: [
        {
          key: 'productFeatureName',
          label: 'Name'
        },
        {
          key: 'operation',
          label: 'Operation'
        },
      ],
      featuresColumns: [
        {
          key: 'name',
          label: 'Name'
        },
        {
          key: 'operation',
          label: 'Operation'
        },
      ],
      sliderRangeValidations: ['currentTitle', 'desireTitle', 'productSliderRangeDetails', 'productSliderRangeBasePrices'],
      rangeSliderDTO: {
        productId: 0,
        currentTitle: '',
        desireTitle: '',
        productSliderRangeDetails: [],
        productFeatureIds: [],
        productSliderRangeBasePrices: []
      }
    }
  },

  async created() {
    await Promise.all([
      this.getProductSliderRange(),
      this.getAllCurrencies()
    ])
  },
  methods: {
    async deleteRangeSlider() {
      let _this = this
      _this.showOverlay = true
      let deleteProductSliderRange = new DeleteProductSliderRange(_this)
      deleteProductSliderRange.setParams({
        id: _this.currentRangeSlider.id
      })
      await deleteProductSliderRange.fetch(function (content) {
        _this.$toast({
          component: ToastificationContent,
          props: {
            title: `success`,
            icon: 'CheckIcon',
            variant: 'success',
            text: `slider Deleted successfully`,
          },
        })

        _this.getProductSliderRange()

        _this.showOverlay = false
      }, function (error) {
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            text: error.message,
            title: `error`,
            variant: 'danger',
          },
        })
        _this.showOverlay = false
      })
    },
    async getAllCurrencies() {
      let _this = this
      _this.showOverlay = true
      let getAllCurrencies = new GetAllCurrencies(_this)
      let data = {
        pageNumber: 1,
        count: 20,
      }
      getAllCurrencies.setParams(data)
      await getAllCurrencies.fetch(function (content) {
        _this.currencies = content.data.getCurrencies
        _this.showOverlay = false
      }, function (error) {
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            text: error.message,
            title: `error`,
            variant: 'danger',
          },
        })
        _this.showOverlay = false
      })
    },

    setSelectedFeatures(items) {

      if (items.length) {
        items.forEach(item => {
          const idx = this.rangeSliderDTO.productFeatureIds.findIndex(e => e.productFeatureId === item.productFeatureId)
          if (idx === -1) {
            this.rangeSliderDTO.productFeatureIds.push(item)
          }
        })
      }

    },
    setSelectedProductFieldId(productFieldId) {
      this.selectedProductFieldId = productFieldId
    },
    createNewRange() {
      this.rangeDTO.ratio = +this.rangeDTO.ratio
      this.rangeDTO.minCondition = +this.rangeDTO.minCondition
      this.rangeDTO.maxCondition = +this.rangeDTO.maxCondition
      this.rangeSliderDTO.productSliderRangeDetails.push(this.rangeDTO)
      this.rangeDTO = {
        minCondition: null,
        maxCondition: null,
        ratio: null,
      }
    },
    createCurrenciesPayload() {
      this.currencies.forEach((item, idx) => {
        this.rangeSliderDTO.productSliderRangeBasePrices.push({
          currencyId: item.id,
          basePrice: this.$refs[`price${idx}`][0]?.$el.value
        })
      })
    },

    async createNewRangeSlider() {
      let _this = this
      _this.showOverlay = true
      _this.rangeSliderDTO.productId = +this.$route.query.productId
      _this.createCurrenciesPayload()
      _this.rangeSliderDTO.productFeatureIds = _this.rangeSliderDTO.productFeatureIds.map(e => e.productFeatureId)
      let isValid = true
      _this.sliderRangeValidations.forEach((validation) => {
        if (!_this.rangeSliderDTO[validation] || !_this.rangeSliderDTO[validation].length) {
          isValid = false
        }
      })
      if (isValid) {

        let createProductRangeSlider = new CreateProductRangeSlider(_this)
        createProductRangeSlider.setParams(_this.rangeSliderDTO)
        await createProductRangeSlider.fetch(function (content) {
          _this.rangeSliderDTO = {
            productId: 0,
            currentTitle: '',
            desireTitle: '',
            productSliderRangeDetails: [],
            productFeatureIds: [],
            productSliderRangeBasePrices: []
          }
          _this.$toast({
            component: ToastificationContent,
            props: {
              title: `success`,
              icon: 'CheckIcon',
              variant: 'success',
              text: `slider created successfully`,
            },
          })
          _this.getProductSliderRange()

          _this.showOverlay = false
        }, function (error) {
          _this.$toast({
            component: ToastificationContent,
            position: 'bottom-center',
            props: {
              text: error.message,
              title: `error`,
              variant: 'danger',
            },
          })
          _this.showOverlay = false
        })
      } else {
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            text: 'Please Fill In All The Fields',
            title: `error`,
            variant: 'danger',
          },
        })
      }
    },

    async getProductSliderRange() {
      let _this = this
      _this.showOverlay = true
      let getProductSliderRangeById = new GetProductSliderRangeById(_this)
      let data = {
        productId: _this.$route.query.productId
      }
      getProductSliderRangeById.setParams(data)
      await getProductSliderRangeById.fetch(function (content) {
        if (content.data) {
          _this.currentRangeSlider = content.data
        } else {
          _this.currentRangeSlider = null
          _this.rangeSliderDTO = {
            productId: 0,
            currentTitle: '',
            desireTitle: '',
            productSliderRangeDetails: [],
            productFeatureIds: [],
            productSliderRangeBasePrices: []
          }
        }
        _this.showOverlay = false
      }, function (error) {
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            text: error.message,
            title: `error`,
            variant: 'danger',
          },
        })
        _this.showOverlay = false
      })
    },
  }
}
</script>

<style scoped>

</style>
